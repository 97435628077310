<template>
  <div id="TYPBlock">
    <div>
      <h2>Уважаемый клиент!</h2>
      <p v-html="txtMsg"></p>
      <p> Спасибо, что пользуетесь нашими услугами! </p>
    </div>

    <p>
      <a target="_blank" href="http://bit.ly/3Mkk8f7" >Установите приложение</a>, чтобы иметь актуальную<br> информацию по договору, моментально вносить платежи и<br> пользоваться нашими акциями
    </p>

  </div>
</template>

<script>
  import {mapState} from  'vuex';
  export default {
  name: "formScreenTYpage",
    computed:{
      ...mapState({
        valueVuex: state => state.Form.sendData,
      }),
      txtMsg(){
        return (this.valueVuex.CALC_WEEK === 52) ?
          'Ваша заявка была предварительно одобрена.<br>Наша системы начал проверку данных. В течение 15 минут с Вами свяжется наш специалист для дальнейших действий.' : 'Ваша заявка одобрена и скоро деньги поступят вам на карту!';
      }
    },
}
</script>

<style scoped>
h2 { padding: 6.47vw 0; color: #0D5740;}
p { color: #0D5740; opacity: 0.6; margin-bottom: 5.88vw }
a { text-decoration-line: underline }
#TYPBlock {  }
#TYPBlock > p { }
#TYPBlock > div { padding: 0 2.94vw 3.82vw; border-radius: 5px; box-shadow: 0 1vw 1vw rgba(0, 0, 0, 0.25); margin-bottom: 8.24vw; display: flex; flex-direction: column; align-items: center; background: #FFFBFB;}
@media (min-width: 760px) {
  h2 { padding: 4.76vw 0 1.69vw 0 }
  p { }
  a { }
  #TYPBlock {}
  #TYPBlock > p { }
  #TYPBlock > div { border-radius: 10px }
}
@media (min-width: 1240px) {
  h2 { padding: 59px 0 21px 0 }
  #anketaSteps > div > h2 {}
  p { margin-bottom: 45px }
  a { }
  #TYPBlock {padding-top: 130px}
  #TYPBlock  p {text-align: center }
  #TYPBlock > div { padding: 0 54px 10px 54px; margin-bottom: 27px; box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25) }
}
</style>
